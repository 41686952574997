import React from "react"
import PresentationList from "./PresentationList"
import Hero from "./Hero"
import TwoColBlockList from "./TwoColBlockList"
import ThreeColBlockList from "./ThreeColBlockList"
import ContentBlock from "./ContentBlock"
import VideoBlock from "./VideoBlock"
import ThreeColGrid from "./ThreeColGrid"
import PricingBlock from "./PricingBlock"

// import Aside from "./Aside"
import "react-unique-key" ;

const Slices = ({ data }) => 

    data.map((item, key) => {
      switch(item.slice_type){
        case "hero":
          return (
            <Hero 
              key={item['unique_key']}
              primary={item.primary}
              items={item.items}
            />
          )
      
        case "two_col_block_list":
          return (
            <TwoColBlockList 
              key={item['unique_key']}
              primary={item.primary}
              items={item.items}
            />
          )

        case "three_col_block_list":
          return (
            <ThreeColBlockList 
              key={item['unique_key']}
              primary={item.primary}
              items={item.items}
            />
          )

        case "content_block":
          return (
            <ContentBlock 
              key={item['unique_key']}
              primary={item.primary}
              items={item.items}
            />
          )
        case "presentation_list":
          return (
            <PresentationList
              key={item['unique_key']}
              primary={item.primary}
              items={item.items}
            />
          )

        case "video_block":
          return (
            <VideoBlock 
              key={item['unique_key']}
              primary={item.primary}
              items={item.items}
            />
        )

        case "three_col_grid":
          return (
            <ThreeColGrid 
              key={item['unique_key']}
              primary={item.primary}
              items={item.items}
            />
        )

        case "pricing_block":
          return (
            <PricingBlock 
              key={item['unique_key']}
              primary={item.primary}
              items={item.items}
            />
        )

        default:
          return (
            <pre key={item['unique_key']}>{JSON.stringify(item, null, 4)}</pre>
          )
        }
      }
    )


export default Slices;