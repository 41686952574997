import React from 'react';
import FormattedText from "../components/FormattedText"
import Button from "../components/button"
import { Link } from "gatsby"
import Img from "gatsby-image";

const LeftAlignedHero = ({primary, items}) => {
  return (
    <section style={{background: primary.bg_color, color: primary.text_color}}>
      <div className="container lg:flex lg:flex-row-reverse">
        <div className="flex-1 pt-16 lg:pt-0">
          {primary.image.localFile.childImageSharp && 
            <Img className="w-4/5 mx-auto" fluid={primary.image.localFile.childImageSharp.fluid} alt={primary.image.alt} />
          }
        </div>
        <div className="pt-4 lg:pt-24 pb-24 flex-1">
          <FormattedText text={primary.title.html} />
          <div className="mt-4">
            <FormattedText text={primary.body_text.html} />
          </div>
          <div className="flex mt-12 lg:w-2/3 mb-8">
            {items.map((item, key) => {
              return (
                <div key={key} className="mr-4 flex-1 text-center">
                  <img className="block mx-auto mb-4" src={item.item_image.url} alt="icon" />
                  <span className="hidden lg:block leading-none text-sm">{item.item_title.text}</span>
                </div>
              )
            })}
          </div>
          {(primary.cta && !primary.cta.isBroken) &&
            <Link to={`/${primary.cta.slug}`}>
              <Button isDark={primary.bg_color === "#000000"} />
            </Link>
          }
        </div>
        
      </div>
    </section>
  )
}

const CenterAlignedHero = ({primary, items}) => {
  return (
    <section className="bg-black text-white h-full lg:h-auto">
      <div className="container text-center">
        <div className="pt-32 pb-12 lg:w-3/4 mx-auto">
          <FormattedText text={primary.title.html} />
          {/* <FormattedText text={primary.body_text.html} /> */}
          <div className="flex mt-12 lg:w-3/4 mx-auto mb-4 lg:mb-8">
            {items.map((item, key) => {
              return (
                <div key={key} className="mt-0 lg:mt-8 mr-4 flex-1">
                  <img className="block mx-auto mb-4" src={item.item_image.url} alt="icon" />
                  <span className="hidden lg:block leading-none text-sm">{item.item_title.text}</span>
                </div>
              )
            })}
          </div>
          {(primary.cta && !primary.cta.isBroken) &&
            <Link to={`/${primary.cta.slug}`}>
              <Button isDark />
            </Link>
          }
        </div>
      </div>
      <div className="mt-4 container-lg bg-red w-full">
        <Img className="mx-auto h-auto" fluid={primary.image.localFile.childImageSharp.fluid} alt={primary.image.alt} />
      </div>
    </section>
  )
}


const Hero = ({primary, items}) => {
  if(primary.text_align) {
    return <LeftAlignedHero primary={primary} items={items} />
  }

  return <CenterAlignedHero primary={primary} items={items} />
};


export default Hero;


