import React from "react"
import Layout from "../templates/Layout"
import "react-unique-key" 
import Slices from "../slices"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

const Webpage = ({ data: { prismicWebpage } }) => {
  const { data } = prismicWebpage;

  return (
    <Layout page={prismicWebpage}>
      <Helmet>
          <script type="application/ld+json">{`
            {
                "@context": "http://schema.org",
                "@type": "WebPage",
                "name": "${data.microdata_name.text}",
                "description": "${data.microdata_description.text}"
            }
        `}</script>
      </Helmet>
      <Slices data={data.body} />
    </Layout>
  )
}

export default Webpage
export const pageQuery = graphql`
  query WebpageBySlug($uid: String!) {
    prismicWebpage(uid: { eq: $uid }) {
      uid
      data {
        body {
          __typename
          ... on PrismicWebpageBodyHero {
            id
            items {
              item_image {
                url
              }
              item_title {
                html
                text
              }
            }
            primary {
              bg_color
              text_align
              text_color
              title {
                html
              }
              body_text {
                html
              }
              cta {
                slug
                isBroken
              }
              image {
                alt
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
              }
            }
            slice_type
          }
          ... on PrismicWebpageBodyBrandBanner {
            id
            primary {
              text_color
              bg_color
              body_text {
                html
              }
              image {
                alt
                url
              }
            }
            slice_type
          }
          ... on PrismicWebpageBodyTwoColBlockList {
            id
            slice_type
            primary {
              bg_color
              text_color
              show_item_icons
              show_body_icons
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
              }
              title {
                html
              }
              body_text {
                html
              }
              cta {
                isBroken
                slug
              }
            }
            items {
              item_text {
                html
              }
              item_icon {
                url
                alt
              }
              item_title {
                html
              }
            }
          }
          ... on PrismicWebpageBodyThreeColBlockList {
            id
            slice_type
            primary {
              bg_image_place
              text_color
              clip
              body_text {
                html
              }
              cta {
                slug
                isBroken
              }
              title {
                html
              }
              bg_color
              image {
                alt
                url
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1280) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
              }
            }
            items {
              item_icon {
                url
                alt
              }
              item_text {
                html
              }
              item_title {
                html
              }
            }
          }
          ... on PrismicWebpageBodyContentBlock {
            id
            primary {
              title {
                html
              }
              image {
                alt
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1280) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
                dimensions {
                  height
                  width
                }
              }
              cta {
                slug
                isBroken
              }
              bg_color
              body_text {
                html
              }
              text_color
              sub_title {
                html
              }
              use_image_dimensions
            }
            slice_type
          }
          ... on PrismicWebpageBodyPresentationList {
            id
            primary {
              bg_color
              is_numbered
              show_body_icons
              text_color
              title {
                html
              }
              body_text {
                html
              }
              cta {
                isBroken
                slug
              }
              image {
                alt
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1280) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
              }
            }
            slice_type
            items {
              item_image {
                url
              }
              item_text {
                html
              }
              item_title {
                html
              }
            }
          }
          ... on PrismicWebpageBodyVideoBlock {
            id
            slice_type
            primary {
              title {
                html
              }
              bg_color
              text_color
              video {
                url
                type
                isBroken
                link_type
              }
            }
          }
          ... on PrismicWebpageBodyThreeColGrid {
            id
            slice_type
            primary {
              bg_color
              show_body_icons
              show_item_icons
              text_color
              title {
                html
              }
              body_text {
                html
              }
              image {
                alt
                url
                localFile {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1280) {
                      ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                }
                dimensions {
                  height
                  width
                }
              }
            }
            items {
              item_icon {
                url
              }
              item_text {
                html
              }
              item_title {
                html
              }
            }
          }
          ... on PrismicWebpageBodyPricingBlock {
            id
            slice_type
            primary {
              bg_color
              text_color
              title {
                html
              }
              body_text {
                html
              }
            }
            items {
              item_cta {
                slug
                isBroken
              }
              item_price {
                html
                text
              }
              item_price_label {
                html
                text
              }
              item_text {
                html
              }
              item_title {
                html
              }
            }
          }

      }
        meta_description {
          text
        }
        meta_title {
          text
        }
        microdata_description {
          text
        }
        microdata_name {
          text
        }
      }
    }
  }
`