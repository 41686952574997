import React from 'react';

const VideoBlock = ({primary}) => {
  return (
    <section className="py-24" style={{background: primary.bg_color, color: primary.text_color}}>
      <div className="container">
        <video controls >

          {/* <source 
            src="/media/examples/flower.webm"
            type="video/webm" /> */}

          <source 
            src={primary.video.url}
            type="video/mp4" />

          Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  </section>
  );
};

export default VideoBlock;