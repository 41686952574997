import React from 'react';
import FormattedText from "../components/FormattedText"
// import Button from "../components/button"
// import { Link } from "gatsby"
import Img from "gatsby-image";

// const _setDimensions = (primary) => {
//   if(primary.use_image_dimensions) {
//     return {
//       width: primary.image.dimensions.width,
//       height: primary.image.dimensions.height,
//       margin: "auto"
      
//     }
//   }
//   return {} 
// }

const ContentBlock = ({ primary}) => {
  return (
    <section style={{background: primary.bg_color, color: primary.text_color}}>
      <div className="lg:flex lg:flex-row-reverse py-8 container">
        <div className="lg:w-1/3 p-8">
          <Img className="lg:mx-4 w-full" fluid={primary.image.localFile.childImageSharp.fluid} alt={primary.image.alt} />
        </div>
        <div className="lg:py-8 lg:pr-64 flex-1">
            <div className="mb-4">
              <FormattedText text={primary.title.html} />
            </div>
            <FormattedText text={primary.body_text.html} />
        </div>
      </div>
    </section>
  );
};

export default ContentBlock;