import React from 'react';
import FormattedText from "../components/FormattedText"
import Button from "../components/button"
import { Link } from "gatsby"


const PricingBlock = ({ items, primary}) => {
  return (
    <section className="py-24" style={{background: primary.bg_color, color: primary.text_color}}>
      <div className="container">
        <header className="text-center mb-32 w-2/3 mx-auto">
            <FormattedText text={primary.title.html} />
            <FormattedText text={primary.body_text.html} />
              
            {(primary.cta && !primary.cta.isBroken) &&
              <Link to={`/${primary.cta.slug}`}>
                <Button />
              </Link>
            }

          </header>

          <div className="grid grid-cols-3 gap-8">
            {items.map((item) => {
              return (
                <div className="p-8 bg-gray-900">
                  <span className="block text-sm">{item.item_price_label.text}</span>
                  <FormattedText text={item.item_title.html} />
                  <span className="block text-sm">Average price</span>
                  <strong className="text-3xl mb-4 block">{item.item_price.text}</strong>
                  <FormattedText text={item.item_text.html} />

                  <div>
                  {(item.item_cta && !item.item_cta.isBroken) &&
                    <Link to={`/${item.item_cta.slug}`}>
                      <Button isDark={primary.bg_color === "#000000"}/>
                    </Link>
                  }
                  </div>
                  
                </div>
              )
            })}
          </div>
      </div>
    </section>
  );
};

export default PricingBlock;